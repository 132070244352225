<template>
  <div class="industrialDesign">
    <div class="industrialDesign GL_declareForm Gl_input">
      <crumbs :title="'设计创新企业 '"></crumbs>
      <Form class="form" ref="formValidate" :model="formValidate" :label-width="0">
        <Row>
          <Col class="col_unit" span="24">单位：万元、平方米、个、%</Col>
        </Row>
        <!-- 企业名称 -->
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">
            企业名称
            </Col>
            <Col class="m_col" span="20">
            <Input readonly v-model.trim="formValidate.industrialDesignEnterprise.companyName
              " class="unit_input" />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">
            企业注册地址
            </Col>
            <Col class="m_col" span="20">
            <Input readonly v-model.trim="formValidate.industrialDesignEnterprise.companyAddress
              " class="unit_input" />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">
            经营地址
            </Col>
            <Col class="m_col" span="20">
            <Input readonly v-model.trim="formValidate.industrialDesignEnterprise.manageAddress
              " class="unit_input" />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">
            社会信用代码
            </Col>
            <Col class="m_col" span="8">
            <Input readonly v-model.trim="formValidate.industrialDesignEnterprise.socialCreditCode
              " class="unit_input" />
            </Col>
            <Col class="m_col m_title" span="4">
            税务登记区
            </Col>
            <Col class="m_col" span="8">
            <Select disabled v-model="formValidate.industrialDesignEnterprise.registeredArea">
              <Option v-for="item in areaList" :key="item.dataId" :value="item.dataId">{{ item.dataValue }}</Option>
            </Select>
            </Col>
          </Row>
        </div>
        <!--  基本情况-->
        <Row>
          <Col span="24">基本情况</Col>
        </Row>
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">所有制性质</Col>
            <Col class="m_col" span="20">
            <div style="width:100%">
              <RadioGroup disabled class="RadioGroup" size="large" v-model="formValidate.industrialDesignEnterprise
                  .unitRegistrationTypeParentId
                ">
                <Radio disabled :key="index" v-for="(item, index) in formValidate.companyType" :label="item.dictId">{{
                  item.dictName }}</Radio>
              </RadioGroup>
              <br />
              <div v-show="childrenList.length > 0" style="border-top:1px dashed #ccc;margin:5px 0"></div>
              <RadioGroup v-show="childrenList.length > 0" class="RadioGroup" size="large" v-model="formValidate.industrialDesignEnterprise.propertyOfOwnership
                ">
                <Radio disabled :key="index2" v-for="(item2, index2) in childrenList" :label="item2.dataId">{{
                  item2.dataValue }}</Radio>
              </RadioGroup>
            </div>
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">
            <span>职工人数</span>
            </Col>
            <Col class="m_col" span="20">
            <Input v-model="formValidate.industrialDesignEnterprise.employeeCount" class="unit_input" />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">
            资产总额
            </Col>
            <Col class="m_col" span="8">
            <Input readonly v-model="formValidate.industrialDesignEnterprise.propertyTotal" class="unit_input" />
            </Col>
            <Col class="m_col m_title" span="4">
            固定资产净值
            </Col>
            <Col class="m_col" span="8">
            <Input readonly v-model="formValidate.industrialDesignEnterprise.fixedAssetsValue
              " class="unit_input" />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">
            资产负债率
            </Col>
            <Col class="m_col" span="8">
            <Input readonly v-model.trim="formValidate.industrialDesignEnterprise
                .assetLiabilityRatioMain
              " class="unit_input" />
            </Col>
            <Col class="m_col m_title" span="4">
            信用评级
            </Col>
            <Col class="m_col" span="8">
            <Input readonly v-model.trim="formValidate.industrialDesignEnterprise.creditRating
              " class="unit_input" />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">
            主要服务领域
            </Col>
            <Col class="m_col" span="20">
            <Input readonly v-model.trim="formValidate.industrialDesignEnterprise.rangeOfServices
              " class="unit_input" />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">企业是否属于</Col>
            <Col class="m_col m_padding" span="20">
            <CheckboxGroup size="large" v-model="CheckboxArray">
              <Checkbox disabled :label="1">上市企业</Checkbox>
              <Checkbox disabled :label="2">高新技术企业</Checkbox>
              <Checkbox disabled :label="4">专精特新企业</Checkbox>
            </CheckboxGroup>
            </Col>
          </Row>
        </div>
        <!-- 管理人员 -->
        <Row>
          <Col span="24">管理人员</Col>
        </Row>
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">
            人员
            </Col>
            <Col class="m_col m_title" span="5">
            姓名
            </Col>
            <Col class="m_col m_title" span="5">
            职务
            </Col>
            <Col class="m_col m_title" span="5">
            联系手机
            </Col>
            <Col class="m_col m_title" span="5">
            电子邮箱
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">
            负责人
            </Col>
            <Col class="m_col" span="5">
            <Input readonly v-model.trim="formValidate.industrialDesignEnterprise.principalName
              " class="unit_input" />
            </Col>
            <Col class="m_col" span="5">
            <Input readonly v-model.trim="formValidate.industrialDesignEnterprise.principalPost
              " class="unit_input" />
            </Col>
            <Col class="m_col" span="5">
            <Input readonly v-model="formValidate.industrialDesignEnterprise.principalPhone" class="unit_input" />
            </Col>
            <Col class="m_col" span="5">
            <Input readonly v-model="formValidate.industrialDesignEnterprise.principalEmail" class="unit_input" />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="4">
            联系人
            </Col>
            <Col class="m_col" span="5">
            <Input readonly v-model.trim="formValidate.industrialDesignEnterprise.contactsName
              " class="unit_input" />
            </Col>
            <Col class="m_col" span="5">
            <Input readonly v-model.trim="formValidate.industrialDesignEnterprise.contactsPost
              " class="unit_input" />
            </Col>
            <Col class="m_col" span="5">
            <Input readonly v-model="formValidate.industrialDesignEnterprise.contactsPhone" class="unit_input" />
            </Col>
            <Col class="m_col" span="5">
            <Input readonly v-model="formValidate.industrialDesignEnterprise.contactsEmail" class="unit_input" />
            </Col>
          </Row>
        </div>
        <!-- 专业人员 -->
        <Row>
          <Col span="24">专业人员</Col>
        </Row>
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="9">
            设计创新团队人数
            </Col>
            <Col class="m_col" span="15">
            <Input readonly v-model="formValidate.industrialDesignEnterprise.employeesCount" class="unit_input" />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="9">
            <span>其中：设计相关学科本科及以上学历人数</span>
            </Col>
            <Col class="m_col" span="5">
            <Input readonly v-model="formValidate.industrialDesignEnterprise.degreeOfCount" class="unit_input" />
            </Col>
            <Col class="m_col m_title" span="5">
            <span>占设计团队比例</span>
            </Col>
            <Col class="m_col" span="5">
            <Input readonly v-model="formValidate.industrialDesignEnterprise.degreeOf" class="unit_input" />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="9">
            <span>具有设计相关学科专业技术职称（职业资格）的人数</span>
            </Col>
            <Col class="m_col" span="5">
            <Input readonly v-model="formValidate.industrialDesignEnterprise.specialtyCount" class="unit_input" />
            </Col>
            <Col class="m_col m_title" span="5">
            <span>占设计团队比例</span>
            </Col>
            <Col class="m_col" span="5">
            <Input readonly v-model="formValidate.industrialDesignEnterprise.specialtyProportion
              " class="unit_input" />
            </Col>
          </Row>
        </div>

        <!-- 硬件条件 -->
        <Row>
          <Col span="24">硬件设备</Col>
        </Row>
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col class="m_col m_title icon_col" span="8">
            <Icon class="icon" type="ios-add-circle-outline" />
            <span class="text_col">
              仪器设备名称
            </span>
            </Col>
            <Col class="m_col m_title" span="8">
            台（套）数
            </Col>
            <Col class="m_col m_title" span="8">
            价值
            </Col>
          </Row>
          <Row type="flex" v-for="(item, index) in formValidate.resourceAndPerfInfo
            .hardwareFacilityList" :key="index" class="m_row">
            <Col class="m_col icon_col" span="8">
            <Icon class="icon" type="ios-remove-circle-outline" />
            <Input readonly v-model.trim="item.apparatusName" class="unit_input" />
            </Col>
            <Col class="m_col" span="8">
            <Input readonly v-model="item.numberOfUnits" class="unit_input" />
            </Col>
            <Col class="m_col" span="8">
            <Input readonly v-model="item.cost" class="unit_input" />
            </Col>
          </Row>
        </div>
        <!-- 软件条件 -->
        <Row>
          <Col span="24">软件条件</Col>
        </Row>
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col class="m_col m_title icon_col" span="8">
            <Icon class="icon" type="ios-add-circle-outline" />
            <span class="text_col">
              软件名称
            </span>
            </Col>
            <Col class="m_col m_title" span="8">
            数量（套）
            </Col>
            <Col class="m_col m_title" span="8">
            价值
            </Col>
          </Row>
          <Row type="flex" v-for="(item, index) in formValidate.resourceAndPerfInfo
            .softwareList" :key="index" class="m_row">
            <Col class="m_col icon_col" span="8">
            <Icon class="icon" type="ios-remove-circle-outline" />
            <Input readonly v-model.trim="item.softwareName" class="unit_input" />
            </Col>
            <Col class="m_col" span="8">
            <Input readonly v-model="item.quantity" class="unit_input" />
            </Col>
            <Col class="m_col" span="8">
            <Input readonly v-model="item.cost" class="unit_input" />
            </Col>
          </Row>
        </div>

        <!-- 经济指标 -->
        <Row>
          <Col span="24">经济指标</Col>
        </Row>
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="6">
            近三年主要指标
            </Col>
            <Col class="m_col m_title" span="6">
            2021年
            </Col>
            <Col class="m_col m_title" span="6">
            2022年
            </Col>
            <Col class="m_col m_title" span="6">
            2023年前三季度
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col style="justify-content: flex-start;" class="m_col m_title" span="6">
            设计投入总额
            </Col>
            <Col class="m_col" span="6">
            <Input readonly v-model="formValidate.industrialEiList[0].designTotalInvestment" class="unit_input" />
            </Col>
            <Col class="m_col" span="6">
            <Input readonly v-model="formValidate.industrialEiList[1].designTotalInvestment" class="unit_input" />
            </Col>
            <Col class="m_col" span="6">
            <Input readonly v-model="formValidate.industrialEiList[2].designTotalInvestment" class="unit_input" />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col style="justify-content: flex-start;" class="m_col m_title" span="6">
            占企业研发设计支出比重
            </Col>
            <Col class="m_col" span="6">
            <Input readonly v-model="formValidate.industrialEiList[0].proportionOfRdDesign" class="unit_input" />
            </Col>
            <Col class="m_col" span="6">
            <Input readonly v-model="formValidate.industrialEiList[1].proportionOfRdDesign" class="unit_input" />
            </Col>
            <Col class="m_col" span="6">
            <Input readonly v-model="formValidate.industrialEiList[2].proportionOfRdDesign" class="unit_input" />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col style="justify-content: flex-start;" class="m_col m_title" span="6">
            其中：设计人员经费支出
            </Col>
            <Col class="m_col" span="6">
            <Input readonly v-model="formValidate.industrialEiList[0].designPersonnel" class="unit_input" />
            </Col>
            <Col class="m_col" span="6">
            <Input readonly v-model="formValidate.industrialEiList[1].designPersonnel" class="unit_input" />
            </Col>
            <Col class="m_col" span="6">
            <Input readonly v-model="formValidate.industrialEiList[2].designPersonnel" class="unit_input" />
            </Col>
          </Row>
        </div>
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col style="justify-content: flex-start;" class="m_col m_title" span="6">
            承担设计项目数
            </Col>
            <Col class="m_col" span="6">
            <Input readonly v-model="formValidate.industrialEiList[0].designProjectCount" class="unit_input" />
            </Col>
            <Col class="m_col" span="6">
            <Input readonly v-model="formValidate.industrialEiList[1].designProjectCount" class="unit_input" />
            </Col>
            <Col class="m_col" span="6">
            <Input readonly v-model="formValidate.industrialEiList[2].designProjectCount" class="unit_input" />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" style="justify-content: flex-start;" span="6">
            其中：完成项目数
            </Col>
            <Col class="m_col" span="6">
            <Input readonly v-model="formValidate.industrialEiList[0].completedProjectCount" class="unit_input" />
            </Col>
            <Col class="m_col" span="6">
            <Input readonly v-model="formValidate.industrialEiList[1].completedProjectCount" class="unit_input" />
            </Col>
            <Col class="m_col" span="6">
            <Input readonly v-model="formValidate.industrialEiList[2].completedProjectCount" class="unit_input" />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" style="justify-content: flex-start;" span="6">
            <span style="margin-left:3em"></span>产业化项目数
            </Col>
            <Col class="m_col" span="6">
            <Input readonly v-model="formValidate.industrialEiList[0].industrializationProjectCount
              " class="unit_input" />
            </Col>
            <Col class="m_col" span="6">
            <Input readonly v-model="formValidate.industrialEiList[1].industrializationProjectCount
              " class="unit_input" />
            </Col>
            <Col class="m_col" span="6">
            <Input readonly v-model="formValidate.industrialEiList[2].industrializationProjectCount
              " class="unit_input" />
            </Col>
          </Row>
        </div>
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col style="justify-content: flex-start;" class="m_col m_title" span="6">
            专利数
            </Col>
            <Col class="m_col" span="6">
            <Input readonly v-model="formValidate.industrialEiList[0].patentLicensingCount" class="unit_input" />
            </Col>
            <Col class="m_col" span="6">
            <Input readonly v-model="formValidate.industrialEiList[1].patentLicensingCount" class="unit_input" />
            </Col>
            <Col class="m_col" span="6">
            <Input readonly v-model="formValidate.industrialEiList[2].patentLicensingCount" class="unit_input" />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" style="justify-content: flex-start;" span="6">
            其中:发明专利（授权数）
            </Col>
            <Col class="m_col" span="6">
            <Input readonly v-model="formValidate.industrialEiList[0].inventPatentCount" class="unit_input" />
            </Col>
            <Col class="m_col" span="6">
            <Input readonly v-model="formValidate.industrialEiList[1].inventPatentCount" class="unit_input" />
            </Col>
            <Col class="m_col" span="6">
            <Input readonly v-model="formValidate.industrialEiList[2].inventPatentCount" class="unit_input" />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" style="justify-content: flex-start;" span="6">
            <span style="margin-left:3em"></span>实用新型（授权数）
            </Col>
            <Col class="m_col" span="6">
            <Input readonly v-model="formValidate.industrialEiList[0].practicalPatent" class="unit_input" />
            </Col>
            <Col class="m_col" span="6">
            <Input readonly v-model="formValidate.industrialEiList[1].practicalPatent" class="unit_input" />
            </Col>
            <Col class="m_col" span="6">
            <Input readonly v-model="formValidate.industrialEiList[2].practicalPatent" class="unit_input" />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" style="justify-content: flex-start;" span="6">
            <span style="margin-left:3em"></span>外观设计（授权数）
            </Col>
            <Col class="m_col" span="6">
            <Input readonly v-model="formValidate.industrialEiList[0].appearancePatent" class="unit_input" />
            </Col>
            <Col class="m_col" span="6">
            <Input readonly v-model="formValidate.industrialEiList[1].appearancePatent" class="unit_input" />
            </Col>
            <Col class="m_col" span="6">
            <Input readonly v-model="formValidate.industrialEiList[2].appearancePatent" class="unit_input" />
            </Col>
          </Row>

          <Row type="flex" class="m_row">
            <Col style="justify-content: flex-start;" class="m_col m_title" span="6">
            版权数（经登记数）
            </Col>
            <Col class="m_col" span="6">
            <Input readonly v-model="formValidate.industrialEiList[0].copyrightAuthorization
              " class="unit_input" />
            </Col>
            <Col class="m_col" span="6">
            <Input readonly v-model="formValidate.industrialEiList[1].copyrightAuthorization
              " class="unit_input" />
            </Col>
            <Col class="m_col" span="6">
            <Input readonly v-model="formValidate.industrialEiList[2].copyrightAuthorization
              " class="unit_input" />
            </Col>
          </Row>
        </div>
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col style="justify-content: flex-start;" class="m_col m_title" span="6">
            设计标准制定数
            </Col>
            <Col class="m_col" span="6">
            <Input readonly v-model="formValidate.industrialEiList[0].standardCount" class="unit_input" />
            </Col>
            <Col class="m_col" span="6">
            <Input readonly v-model="formValidate.industrialEiList[1].standardCount" class="unit_input" />
            </Col>
            <Col class="m_col" span="6">
            <Input readonly v-model="formValidate.industrialEiList[2].standardCount" class="unit_input" />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col style="justify-content: flex-start;" class="m_col m_title" span="6">
            其中：国际或国家标准
            </Col>
            <Col class="m_col" span="6">
            <Input readonly v-model="formValidate.industrialEiList[0].nationalStandardCount" class="unit_input" />
            </Col>
            <Col class="m_col" span="6">
            <Input readonly v-model="formValidate.industrialEiList[1].nationalStandardCount" class="unit_input" />
            </Col>
            <Col class="m_col" span="6">
            <Input readonly v-model="formValidate.industrialEiList[2].nationalStandardCount" class="unit_input" />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" style="justify-content: flex-start;" span="6">
            <span style="margin-left:3em"></span>行业标准
            </Col>
            <Col class="m_col" span="6">
            <Input readonly v-model="formValidate.industrialEiList[0].industryStandardCount" class="unit_input" />
            </Col>
            <Col class="m_col" span="6">
            <Input readonly v-model="formValidate.industrialEiList[1].industryStandardCount" class="unit_input" />
            </Col>
            <Col class="m_col" span="6">
            <Input readonly v-model="formValidate.industrialEiList[2].industryStandardCount" class="unit_input" />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" style="justify-content: flex-start;" span="6">
            <span style="margin-left:3em"></span>地方或团体标准
            </Col>
            <Col class="m_col" span="6">
            <Input readonly v-model="formValidate.industrialEiList[0].localStandardCount" class="unit_input" />
            </Col>
            <Col class="m_col" span="6">
            <Input readonly v-model="formValidate.industrialEiList[1].localStandardCount" class="unit_input" />
            </Col>
            <Col class="m_col" span="6">
            <Input readonly v-model="formValidate.industrialEiList[2].localStandardCount" class="unit_input" />
            </Col>
          </Row>
        </div>
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col style="justify-content: flex-start;" class="m_col m_title" span="6">
            企业营业收入
            </Col>
            <Col class="m_col" span="6">
            <Input readonly v-model="formValidate.industrialEiList[0].companyIncome" class="unit_input" />
            </Col>
            <Col class="m_col" span="6">
            <Input readonly v-model="formValidate.industrialEiList[1].companyIncome" class="unit_input" />
            </Col>
            <Col class="m_col" span="6">
            <Input readonly v-model="formValidate.industrialEiList[2].companyIncome" class="unit_input" />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col style="justify-content: flex-start;" class="m_col m_title" span="6">
            其中：设计服务收入
            </Col>
            <Col class="m_col" span="6">
            <Input readonly v-model="formValidate.industrialEiList[0].industrialDesignTotal" class="unit_input" />
            </Col>
            <Col class="m_col" span="6">
            <Input readonly v-model="formValidate.industrialEiList[1].industrialDesignTotal" class="unit_input" />
            </Col>
            <Col class="m_col" span="6">
            <Input readonly v-model="formValidate.industrialEiList[2].industrialDesignTotal" class="unit_input" />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" style="justify-content: flex-start;" span="6">
            <span style="margin-left:3em"></span>设计收入占比
            </Col>
            <Col class="m_col" span="6">
            <Input readonly v-model="formValidate.industrialEiList[0].industrialDesignDuty" class="unit_input" />
            </Col>
            <Col class="m_col" span="6">
            <Input readonly v-model="formValidate.industrialEiList[1].industrialDesignDuty" class="unit_input" />
            </Col>
            <Col class="m_col" span="6">
            <Input readonly v-model="formValidate.industrialEiList[2].industrialDesignDuty" class="unit_input" />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col style="justify-content: flex-start;" class="m_col m_title" span="6">
            利润总额
            </Col>
            <Col class="m_col" span="6">
            <Input readonly v-model="formValidate.industrialEiList[0].profitTotal" class="unit_input" />
            </Col>
            <Col class="m_col" span="6">
            <Input readonly v-model="formValidate.industrialEiList[1].profitTotal" class="unit_input" />
            </Col>
            <Col class="m_col" span="6">
            <Input readonly v-model="formValidate.industrialEiList[2].profitTotal" class="unit_input" />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col style="justify-content: flex-start;" class="m_col m_title" span="6">
            纳税总额
            </Col>
            <Col class="m_col" span="6">
            <Input readonly v-model="formValidate.industrialEiList[0].profitAndTaxTotal" class="unit_input" />
            </Col>
            <Col class="m_col" span="6">
            <Input readonly v-model="formValidate.industrialEiList[1].profitAndTaxTotal" class="unit_input" />
            </Col>
            <Col class="m_col" span="6">
            <Input readonly v-model="formValidate.industrialEiList[2].profitAndTaxTotal" class="unit_input" />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col style="justify-content: flex-start;" class="m_col m_title" span="6">
            资产负债率
            </Col>
            <Col class="m_col" span="6">
            <Input readonly v-model="formValidate.industrialEiList[0].assetLiabilityRatio" class="unit_input" />
            </Col>
            <Col class="m_col" span="6">
            <Input readonly v-model="formValidate.industrialEiList[1].assetLiabilityRatio" class="unit_input" />
            </Col>
            <Col class="m_col" span="6">
            <Input readonly v-model="formValidate.industrialEiList[2].assetLiabilityRatio" class="unit_input" />
            </Col>
          </Row>
          <Row type="flex" class="m_row">
            <Col style="justify-content: flex-start;" class="m_col m_title" span="6">
            现金流情况 (经营活动现金流量净额，可附说明)
            </Col>
            <Col class="m_col" span="6">
            <Input readonly v-model="formValidate.industrialEiList[0].cashSituation" class="unit_input" />
            </Col>
            <Col class="m_col" span="6">
            <Input readonly v-model="formValidate.industrialEiList[1].cashSituation" class="unit_input" />
            </Col>
            <Col class="m_col" span="6">
            <Input readonly v-model="formValidate.industrialEiList[2].cashSituation" class="unit_input" />
            </Col>
          </Row>
        </div>
        <!-- 近三年设计成果获奖情况 -->
        <Row>
          <Col span="24">近三年设计成果获奖情况</Col>
        </Row>
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col class="m_col m_title icon_col" span="6">
            <Icon class="icon" type="ios-add-circle-outline" />
            <span class="text_col">
              获奖作品
            </span>
            </Col>
            <Col class="m_col m_title" span="6">
            奖项名称
            </Col>
            <Col class="m_col m_title" span="6">
            获得时间
            </Col>
            <Col class="m_col m_title" span="6">
            授奖部门(或机构)
            </Col>
          </Row>
          <Row type="flex" v-for="(item, index) in formValidate.resourceAndPerfInfo
            .awardsInfoList" :key="index" class="m_row">
            <Col class="m_col icon_col" span="6">
            <Icon class="icon" type="ios-remove-circle-outline" />
            <Input readonly v-model.trim="item.awardsWorks" class="unit_input" />
            </Col>
            <Col class="m_col" span="6">
            <Input readonly v-model.trim="item.awardsName" class="unit_input" />
            </Col>
            <Col class="m_col" span="6">
            <DatePicker readonly :value="item.obtainTime" type="date" placeholder="选择时间" style="width: 200px">
            </DatePicker>
            </Col>
            <Col class="m_col" span="6">
            <Input readonly v-model.trim="item.authorizationDepartment" class="unit_input" />
            </Col>
          </Row>
        </div>
        <!-- 近三年开展公共服务情况 -->
        <Row>
          <Col span="24">近三年开展公共服务情况</Col>
        </Row>
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col class="m_col m_title icon_col" span="6">
            <Icon class="icon" type="ios-add-circle-outline" />
            <span class="text_col">
              服务类型
            </span>
            </Col>
            <Col class="m_col m_title" span="6">
            项目名称
            </Col>
            <Col class="m_col m_title" span="6">
            开展时间
            </Col>
            <Col class="m_col m_title" span="6">
            组织部门
            </Col>
          </Row>
          <Row v-for="(item, index) in formValidate.resourceAndPerfInfo
            .serviceList" :key="index" class="m_row">
            <Col class="m_col icon_col" span="6">
            <Icon class="icon" type="ios-remove-circle-outline" />
            <Input readonly v-model.trim="item.serviceType" class="unit_input" />
            </Col>
            <Col class="m_col" span="6">
            <Input readonly v-model="item.serviceName" class="unit_input" />
            </Col>
            <Col class="m_col" span="6">
            <DatePicker readonly :value="item.startTime" type="date" placeholder="选择时间" style="width: 200px"></DatePicker>
            </Col>
            <Col class="m_col" span="6">
            <Input readonly v-model="item.department" class="unit_input" />
            </Col>
          </Row>
        </div>
        <!-- 近三年主要设计成果产业化情况 -->
        <Row>
          <Col span="24">近三年主要设计成果产业化情况</Col>
        </Row>
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col class="m_col m_title icon_col" span="6">
            <Icon class="icon" type="ios-add-circle-outline" />
            <span class="text_col">
              项目名称
            </span>
            </Col>
            <Col class="m_col m_title" span="5">
            客户企业
            </Col>
            <Col class="m_col m_title" span="5">
            完成交付时间
            </Col>
            <Col class="m_col m_title" span="8">
            产业化效果
            </Col>
          </Row>
          <Row type="flex" class="m_row" v-for="(item, index) in formValidate.resourceAndPerfInfo
            .designResultList" :key="index">
            <Col class="m_col icon_col" span="6">
            <Icon class="icon" type="ios-remove-circle-outline" />
            <Input readonly v-model.trim="item.projectName" class="unit_input" />
            </Col>
            <Col class="m_col" span="5">
            <Input readonly v-model.trim="item.clientCompany" class="unit_input" />
            </Col>
            <Col class="m_col" span="5">
            <!-- <Input
            readonly v-model="item.time" class="unit_input" /> -->
            <DatePicker readonly :value="item.finishDeliveryTime" type="date" placeholder="选择时间" style="width: 200px">
            </DatePicker>
            </Col>
            <Col class="m_col" span="8">
            <Input readonly type="textarea" v-model="item.industrializationResult" @on-change="
              item.industrializationResult = $util.ToDBC(
                item.industrializationResult,
                $event
              )
              " class="unit_input" placeholder="简述：项目内容、设计理念、设计创新点和技术水平情况，形成的新产品，为企业带来经济效益和社会效益情况等内容。"
              :autosize="{ minRows: 3, maxRows: 8 }" />
            </Col>
          </Row>
        </div>
        <!-- 牵头或参与完成国家、本市重大设计创新项目情况 -->
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="24">
            牵头或参与完成国家、本市重大设计创新项目情况
            </Col>
          </Row>
          <Row class="m_row textarea_row">
            <Col span="24">
            <Input readonly v-model="formValidate.statusQuoPlan.nationalMajorProgramInfo" @on-change="
              formValidate.statusQuoPlan.nationalMajorProgramInfo = $util.ToDBC(
                formValidate.statusQuoPlan.nationalMajorProgramInfo,
                $event
              )
              " type="textarea" :autosize="{ minRows: 5, maxRows: 8 }"
              placeholder="近三年牵头或参与完成国家、本市重大研发、文化创意、重点工程、高质量发展产业项目等设计创新工作情况。"></Input>
            </Col>
          </Row>
        </div>
        <!-- 组织或参与国家、本市重要设计类活动情况 -->
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="24">
            组织或参与国家、本市重要设计类活动情况
            </Col>
          </Row>
          <Row class="m_row textarea_row">
            <Col span="24">
            <Input readonly v-model="formValidate.statusQuoPlan.activitySituation" @on-change="
              formValidate.statusQuoPlan.activitySituation = $util.ToDBC(
                formValidate.statusQuoPlan.activitySituation,
                $event
              )
              " type="textarea" :autosize="{ minRows: 5, maxRows: 8 }"
              placeholder="近三年牵头或参与设计创新重要活动，包括国家、本市重要设计类活动情况，承担省部级设计创新课题研究，与中小企业开展设计创新项目合作、为中小企业提供设计创新咨询服务，获得省部级示范认定等情况，以及必要的佐证材料。（可另附页）"></Input>
            </Col>
          </Row>
        </div>
        <!-- 发展情况说明 -->
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="24">
            发展情况说明
            </Col>
          </Row>
          <Row class="m_row textarea_row">
            <Col span="24">
            <Input readonly v-model="formValidate.statusQuoPlan.operationInfo" @on-change="
              formValidate.statusQuoPlan.operationInfo = $util.ToDBC(
                formValidate.statusQuoPlan.operationInfo,
                $event
              )
              " type="textarea" :autosize="{ minRows: 5, maxRows: 8 }"
              placeholder="重点介绍设计创新企业发展经历、组织体系、团队建设、运营模式、主要业绩、管理制度、产学研合作情况、履行设计创新公共服务平台职责等，以及必要的佐证材料。（可另附页）"></Input>
            </Col>
          </Row>
        </div>
        <!-- 中心今后三年目标与规划情况 -->
        <div class="m_item">
          <Row type="flex" class="m_row">
            <Col class="m_col m_title" span="24">
            中心今后三年目标与规划情况
            </Col>
          </Row>
          <Row class="m_row textarea_row">
            <Col span="24">
            <Input readonly v-model="formValidate.statusQuoPlan.targetAndPlan" @on-change="
              formValidate.statusQuoPlan.targetAndPlan = $util.ToDBC(
                formValidate.statusQuoPlan.targetAndPlan,
                $event
              )
              " type="textarea" :autosize="{ minRows: 5, maxRows: 8 }"
              placeholder="重点是中心今后三年创新建设、有效投入、设计成果等主要指标，以及组织体系建设、运营模式创新、团队建设、履行设计创新公共服务平台职责等规划和措施等情况。"></Input>
            </Col>
          </Row>
        </div>
      </Form>
    </div>
    <!-- 页面五 -->
    <div class="member GL_declareForm Gl_input">
      <Form class="form" ref="formValidateFive" :model="formValidateFive" :label-width="0">
        <!-- 工业设计团队带头人及主要成员情况 -->
        <!-- 基本情况 -->
        <div class="forItem" :key="index" v-for="(item, index) in formValidateFive.foregoerMemberList">
          <crumbs :title="'设计创新团队带头人及主要成员情况 '"></crumbs>
          <Row>
            <Col span="24">基本情况</Col>
          </Row>
          <div class="iconBox">
            <Icon class="icon" type="ios-add-circle-outline" />
            <Icon class="icon" type="ios-remove-circle-outline" />
          </div>
          <div class="m_item">
            <Row type="flex" class="m_row">
              <Col class="m_col m_title" span="4">姓名</Col>
              <Col class="m_col" span="8">
              <Input readonly v-model="item.peopleName" class="unit_input" />
              </Col>
              <Col class="m_col m_title" span="4">
              <span>性别</span>
              </Col>
              <Col class="m_col" span="8">
              <!-- <Input v-model="item.gender" class="unit_input" /> -->
              <RadioGroup size="large" v-model="item.gender">
                <Radio disabled label="2">男</Radio>
                <Radio disabled label="1">女</Radio>
              </RadioGroup>
              </Col>
            </Row>
            <Row type="flex" class="m_row">
              <Col class="m_col m_title" span="4">出生年月</Col>
              <Col class="m_col" span="8">
              <Input readonly v-model="item.dateOfBirth" class="unit_input" />
              <!-- <DatePicker
                  readonly
                  v-model="item.dateOfBirth"
                  @on-change="item.dateOfBirth = $event"
                  type="date"
                  placeholder="选择时间"
                ></DatePicker> -->
              </Col>
              <Col class="m_col m_title" span="4">
              <span>国籍</span>
              </Col>
              <Col class="m_col" span="8">
              <Input readonly v-model="item.nationality" class="unit_input" />
              </Col>
            </Row>
            <Row type="flex" class="m_row">
              <Col class="m_col m_title" span="4">公司职务</Col>
              <Col class="m_col" span="8">
              <Input readonly v-model="item.companyPost" class="unit_input" />
              </Col>
              <Col class="m_col m_title" span="4">
              <span>手机号码</span>
              </Col>
              <Col class="m_col" span="8">
              <Input readonly v-model="item.phone" class="unit_input" />
              </Col>
            </Row>
          </div>
          <!-- 职称学历 -->
          <Row>
            <Col span="24">职称学历</Col>
          </Row>
          <div class="m_item">
            <Row type="flex" class="m_row">
              <Col class="m_col m_title" span="4">专业职称</Col>
              <Col class="m_col" span="20">
              <Input readonly v-model="item.specialty" class="unit_input" />
              </Col>
            </Row>
            <Row type="flex" class="m_row">
              <Col class="m_col m_title" span="4">
              学历、学位
              <br />（毕业院校及专业）
              </Col>
              <Col class="m_col" span="20">
              <Input readonly v-model="item.education" class="unit_input" />
              </Col>
            </Row>
          </div>
          <!-- 工作经历及成绩 -->
          <div class="m_item">
            <Row class="m_row">
              <Col style="height: 120px" class="m_col m_title" span="4">工作经历及成绩</Col>
              <Col style="height: 120px" class="m_col" span="20">
              <Input readonly v-model="item.workExperience" type="textarea" :autosize="{ minRows: 5, maxRows: 5 }"
                placeholder="（从事设计创新领域的主要工作经历和取得的成绩）"></Input>
              </Col>
            </Row>
          </div>
        </div>
        <!-- 工业设计团队带头人及主要成员情况 -->
      </Form>
    </div>
    <!-- 页面六 -->
    <div class="attachmentUpload GL_declareForm industryUpload">
      <crumbs :title="'附件上传 '"></crumbs>
      <Form class="form" ref="formValidateSix" :model="formValidateSix" :label-width="0">
        <div class="m_item">
          <Row :key="item.sfId" v-for="item in formValidateSix.programFiles" type="flex" class="m_row">
            <Col style="padding:8px" class="m_col m_title" span="8">
            <!-- <div class="text_box"> -->
            <span class="spantext">{{ item.declareMaterialName }}</span>
            <!-- </div> -->
            </Col>
            <Col class="s_col" span="16">
            <template v-for="(item2, index) in item.programFilesList">
              <div class="picText">
                <p>{{ item2.fileName }}</p>
                <div @click="preview(item2.filePath)" class="demo-upload-list-cover">
                  <Icon type="ios-eye" style="color:#3385FF" size="30"></Icon>
                </div>
              </div>
            </template>
            </Col>
          </Row>
        </div>
      </Form>
    </div>
    <div style="text-align: center; ">
      <button @click="goBack" class="GL_cancel">返回</button>
    </div>
  </div>
</template>

<script>
import crumbs from "../../components/crumbs";
import waves from "../../directive/waves";
export default {
  components: {
    crumbs
  },
  directives: { waves },
  name: "",
  props: {},
  data() {
    return {
      CheckboxArray: [],
      childrenList: [],
      // 区域列表
      areaList: [],
      // countNum: 0,
      programId: null,
      formValidate: {
        programId: null,
        pageStatus: 1,
        declareType: 3002,
        resourceAndPerfInfo: {
          awardsInfoList: [
            {
              awardsWorks: null,
              awardsName: null,
              obtainTime: null,
              authorizationDepartment: null,
              programType: 1
            }
          ],
          designResultList: [
            {
              projectName: null,
              clientCompany: null,
              finishDeliveryTime: null,
              industrializationResult: null,
              programType: 1
            }
          ],
          hardwareFacilityList: [
            {
              apparatusName: null,
              numberOfUnits: null,
              cost: null,
              useInfo: null,
              programType: 1
            }
          ],
          softwareList: [
            {
              softwareName: null,
              quantity: null,
              cost: null,
              useInfo: null,
              programType: 1
            }
          ],
          publicService: [
            {
              serviceType: null,
              projectName: null,
              tame: null,
              section: null,
              programType: 1
            }
          ]
        },
        statusQuoPlan: {
          //牵头或参与完成国家、本市重大设计创新项目情况
          nationalMajorProgramInfo: null,
          //组织或参与国家、本市重要设计类活动情况
          activitySituation: null,
          //发展情况说明
          operationInfo: null,
          //中心今后三年目标与规划情况
          targetAndPlan: null,
          programType: 1
        },
        industrialDesignEnterprise: {
          programId: null
          // companyName: null,
          // companyAddress: null,
          // manageAddress: null, //企业经营地址
          // registeredArea: null,
          // thatTime: null, //获得区级工业设计中心认定时间
          // establishedTime: null, //成立时间
          // registeredCapital: null, //注册资本
          // propertyTotal: null, //资产总额
          // employeeCount: null, //职工人数
          // propertyOfOwnership: null, // "所有制性质",
          // creditRating: null, //信用等级
          // companyIsBelong: null, //企业是否属于(1:上市企业 2：高新技术企业)
          // rangeOfServices: null,
          // principalName: null,
          // principalPost: null,
          // principalPhone: null,
          // contactsName: null,
          // contactsPost: null,
          // contactsPhone: null,
          // employeesCount: null, //工业设计从业人数
          // degreeOf: null, //其中：本科及以上学历人员数（含具有技师及以上职业资格人员、高级专业技术职务人员）和占比
          // degreeOfCount: null,
          // specialtyCount: null, //具有技师（⾼级技师）职业资格的⼈员、⾼级专业技术职务⼈员数
          // specialtyProportion: null //具有技师（⾼级技师）职业资格的⼈员、⾼级专业技术职务⼈员数及占⽐
        },
        industrialEiList: [
          //经济指标、工业设计成果
          {
            companyIncome: null, //公司营业收入
            industrialDesignDuty: null, //其中：工业设计服务收入及占比
            profitAndTaxTotal: null, //利税总额
            profitTotal: null, //利润总额
            projectFinishCount: null, //工业设计项目完成数
            epibolyProjectCount: null, //承担服务外包项目数
            foreignProjectCount: null, //其中:承担国外项目数
            patentLicensingCount: null, //专利授权数
            inventPatentCount: null, //其中:发明专利数
            copyrightAuthorization: null, //版权授权数
            nationalStandard: null, //国家标准
            industryStandard: null, //行业标准
            localStandard: null, //地方标准
            operatingIncome: null, //营业收入
            totalProfit: null, //利润总额
            assetLiabilityRatio: null, //资产负债率
            year: 2021 //年份
          },
          {
            companyIncome: null,
            industrialDesignDuty: null,
            profitAndTaxTotal: null,
            profitTotal: null,
            projectFinishCount: null,
            epibolyProjectCount: null,
            foreignProjectCount: null,
            patentLicensingCount: null,
            inventPatentCount: null,
            copyrightAuthorization: null,
            nationalStandard: null, //国家标准
            industryStandard: null, //行业标准
            localStandard: null, //地方标准
            operatingIncome: null, //营业收入
            totalProfit: null, //利润总额
            assetLiabilityRatio: null, //资产负债率
            year: 2022
          },
          {
            companyIncome: null,
            industrialDesignDuty: null,
            profitAndTaxTotal: null,
            profitTotal: null,
            projectFinishCount: null,
            epibolyProjectCount: null,
            foreignProjectCount: null,
            patentLicensingCount: null,
            inventPatentCount: null,
            copyrightAuthorization: null,
            nationalStandard: null, //国家标准
            industryStandard: null, //行业标准
            localStandard: null, //地方标准
            operatingIncome: null, //营业收入
            totalProfit: null, //利润总额
            assetLiabilityRatio: null, //资产负债率
            year: 2023
          }
        ],
        foregoerMemberList: [
          {
            peopleName: null,
            gender: null,
            dateOfBirth: null,
            nationality: null,
            companyPost: null,
            phone: null,
            specialty: null,
            education: null,
            workExperience: null,
            programType: 2
          },
          {
            peopleName: null,
            gender: null,
            dateOfBirth: null,
            nationality: null,
            companyPost: null,
            phone: null,
            specialty: null,
            education: null,
            workExperience: null,
            programType: 2
          }
        ]
      },
      //   页面五
      formValidateFive: {
        programId: null,
        pageStatus: 5,
        declareType: 3002,
        foregoerMemberList: [
          {
            peopleName: null,
            gender: null,
            dateOfBirth: null,
            nationality: null,
            companyPost: null,
            phone: null,
            specialty: null,
            education: null,
            workExperience: null,
            programType: 2
          },
          {
            peopleName: null,
            gender: null,
            dateOfBirth: null,
            nationality: null,
            companyPost: null,
            phone: null,
            specialty: null,
            education: null,
            workExperience: null,
            programType: 2
          }
        ]
      },
      //   页面六
      formValidateSix: {
        programId: null,
        pageStatus: 6,
        declareType: 3002,
        programFiles: []
      },

      ruleValidate: {
        validateThanZero: [
          // 验证大于等于0的所有数，小数点后保留两位小数
          { validator: this.$util.validateThanZero, trigger: "blur" }
        ],
        validatePositiveInteger: [
          // 验证大于等于0的所有整数
          { validator: this.$util.validatePositiveInteger, trigger: "blur" }
        ],
        validateCellPhoneNumber: [
          { validator: this.$util.validateCellPhoneNumber, trigger: "blur" }
        ],
        validateMailbox: [
          { validator: this.$util.validateMailbox, trigger: "blur" }
        ],
        validatePercentageThanZero: [
          { validator: this.$util.validatePercentageThanZero, trigger: "blur" }
        ],
        validateFloatingNumber: [
          { validator: this.$util.validateFloatingNumber, trigger: "blur" }
        ]
      }
    };
  },
  beforeRouteEnter(to, from, next) {
    if (from.path == "/manage") {
      next(vm => {
        vm.$store.commit("getWhereFrom", "manage");
      });
    }
    next();
  },
  created() {
    this.programId = this.$store.state.industryProgramId;
    this.formValidate.programId = this.$store.state.industryProgramId;
    this.queryPage();
    this.pageFive();
    this.pageSix();
    // 获取区域列表
    this.getAreaList();
    this.formValidate.industrialDesignEnterprise.programId = this.$store.state.industryProgramId;
  },
  computed: {},

  mounted() { },
  methods: {
    /*初始化数据
     *method queryPage
     */
    async queryPage() {
      // console.log(this.$route.query);
      if (this.$route.query.programId) {
        this.$store.commit("getIndustryProgramId", this.$route.query.programId);
      }
      if (this.$route.query.specialId) {
        this.$store.commit("getIndustrySpecialId", this.$route.query.specialId);
      }
      let res = await this.$http.post(
        "shsj_declare/industralDesign/getIndustralDesignInfo",
        {
          programId:
            this.$route.query.programId || this.$store.state.industryProgramId,
          pageStatus: 1,
          declareType: 3002
        }
      );
      if (res.messageCode === 10000) {
        if (res.result.result != null) {
          this.formValidate.industrialDesignEnterprise =
            res.result.result.IndustrialDesignEnterpriseInfo;
          if (
            this.formValidate.industrialDesignEnterprise.companyName == null
          ) {
            this.formValidate.industrialDesignEnterprise.companyName =
              localStorage.companyName;
          }
          if (
            res.result.result.IndustrialDesignEnterpriseInfo.industrialEiList
              .length == 3
          ) {
            this.formValidate.industrialEiList =
              res.result.result.IndustrialDesignEnterpriseInfo.industrialEiList;
          }
          if (res.result.result.resourceAndPerfInfo) {
            this.formValidate.resourceAndPerfInfo =
              res.result.result.resourceAndPerfInfo;
          }
          if (res.result.result.plan) {
            this.formValidate.statusQuoPlan = res.result.result.plan;
          }

          this.formValidate.industrialDesignEnterprise.unitRegistrationTypeParentId =
            this.formValidate.industrialDesignEnterprise
              .unitRegistrationTypeParentId - 0;
          this.formValidate.industrialDesignEnterprise.propertyOfOwnership =
            this.formValidate.industrialDesignEnterprise.propertyOfOwnership -
            0;
          this.formValidate.companyType = res.result.result.companyType;
          let index = this.formValidate.companyType.findIndex(
            item =>
              item.dictId ==
              this.formValidate.industrialDesignEnterprise
                .unitRegistrationTypeParentId
          );
          if (index == -1) {
            this.childrenList = [];
          } else {
            this.childrenList = this.formValidate.companyType[
              index
            ].sysDictDataList;
          }
          // 多选框
          if (
            this.formValidate.industrialDesignEnterprise.companyIsBelong == 1
          ) {
            this.CheckboxArray = [1];
          } else if (
            this.formValidate.industrialDesignEnterprise.companyIsBelong == 2
          ) {
            this.CheckboxArray = [2];
          } else if (
            this.formValidate.industrialDesignEnterprise.companyIsBelong == 3
          ) {
            this.CheckboxArray = [1, 2];
          } else if (
            this.formValidate.industrialDesignEnterprise.companyIsBelong == 4
          ) {
            this.CheckboxArray = [4];
          } else if (
            this.formValidate.industrialDesignEnterprise.companyIsBelong == 5
          ) {
            this.CheckboxArray = [1, 4];
          } else if (
            this.formValidate.industrialDesignEnterprise.companyIsBelong == 6
          ) {
            this.CheckboxArray = [2, 4];
          } else if (
            this.formValidate.industrialDesignEnterprise.companyIsBelong == 7
          ) {
            this.CheckboxArray = [1, 2, 4];
          }
        }
      }
    },
    async pageFive() {
      let res = await this.$http.post(
        "shsj_declare/industralDesign/getIndustralDesignInfo",
        {
          programId: this.$route.query.programId,
          pageStatus: 5,
          declareType: 3002
        }
      );
      if (res.messageCode === 10000) {
        if (res.result.result != null && res.result.result.length > 0) {
          this.formValidateFive.foregoerMemberList = res.result.result;
        }
      }
    },
    async pageSix() {
      let res = await this.$http.post(
        "shsj_declare/industralDesign/getIndustralDesignInfo",
        {
          programId: this.$route.query.programId,
          pageStatus: 6,
          declareType: 3002,
          specialId: this.$route.query.specialId
        }
      );
      if (res.messageCode === 10000) {
        if (res.result.result != null) {
          this.formValidateSix.programFiles = res.result.result;
        }
      }
    },
    /****/
    radioChange(e) {
      let list = this.formValidate.companyType.filter(item => item.dictId == e);
      if (list.length > 0) {
        this.childrenList = list[0].sysDictDataList;
        this.formValidate.industrialDesignEnterprise.propertyOfOwnership = null;
      }
    },
    /*获取区域列表
     *method getAreaList
     */
    async getAreaList() {
      let { result } = await this.$http.get(
        "shsj_declare/sysDict/queryDictDataList",
        {
          dictId: 8000
        }
      );
      this.areaList = result.dictDataList;
      let shanghaiIndex = this.areaList.findIndex(
        item => item.dataValue == "上海市"
      );
      this.areaList.splice(shanghaiIndex, 1);
    },
    /*初始化专项附件
     *method  initAttachment
     **/
    async initAttachment() {
      var res = await this.$http.post("shsj_declare/sysDict/getSpecialFile", {
        specialId: this.$route.query.specialId,
        declareType: 3002
      });
      if (res.messageCode == 10000) {
        this.specialFiles = res.result.specialFiles;
      }
    },
    /** 返回
     * method goBack
     * */
    goBack() {
      this.$router.go(-1);
    },
    /*method
     **点击预览
     */

    preview(path) {
      window.open(path);
    }
  }
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
.industryUpload {
  .spantext {
    max-width: 100%;
    display: inline-block;
    overflow-wrap: break-word;
    /*  css3属性，当内容超过指定容器的边界时是否断行  */
  }

  .m_item {
    .s_col {
      border-right: 1px solid #e8ecf5;
      border-bottom: 1px solid #e8ecf5;

      .ivu-btn {
        margin: 5px;
        border-radius: 20px;
        padding: 10px 50px;
      }

      .picText {
        padding: 10px;
        display: inline-block;
      }
    }
  }
}

.member {
  .forItem {
    position: relative;

    .iconBox {
      // width: 50px;
      position: absolute;
      left: 310px;
      top: 9px;
      // right: 0;
      z-index: 888;

      .icon {
        padding: 0px 5px;
        color: #3385ff;
        font-size: 20px;
        font-weight: 700;
      }
    }
  }
}
</style>
